@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
    width: 244px;
    min-height: 149px;
    position: relative;
    display: flex;
    justify-content: center;
}

.folderTop {
    width: 52%;
    position: absolute;
    top: -18px;
    left: 0;
    transition: top 0.3s ease-out;
}

.folderFront {
    width: 358px;
    min-height: 160px;
    height: 100%;
    flex: 1;
    position: relative;
    border: 1px solid transparent;
    border-radius: 20px;
    box-sizing: border-box;
    padding-top: 18px;
    padding-right: 28px;
    padding-bottom: 18px;
    padding-left: 28px;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    &:hover {
        .circle {
            transition: all 0.5s linear;
            opacity: 0.4;
            transform: scale(150);
        }
    }
    &_future {
        border: 1px solid #d9d9d9;
        .circle {
            background: $personal3_future;
        }
    }
    &_cyberpunk {
        .circle {
            background: $text-light;
        }
    }
}

.text {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    z-index: 1;
}

.title {
    font-size: 20px;
    letter-spacing: 1px;
    transition: color 0.3s ease-out;
    @include font-600;

    &_cyberpunk {
        color: $text-light;
    }
}

.description {
    font-size: 11px;
    margin-left: 3px;
    color: rgba($color: $text-dark, $alpha: 0.6);
    transition: color 0.3s ease-out;
    @include font-500;

    &_cyberpunk {
        color: rgba($color: $text-light, $alpha: 0.8);
    }
}

.icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 22px;
    padding-right: 22px;
    padding-bottom: 16px;
    padding-left: 0;
    box-sizing: border-box;
    z-index: 1;
}

.topIcon {
    height: 18px;
}

.bottomIcon {
    height: 18px;
    transition: transform 0.3s ease-out;
}

.circle {
    position: absolute;
    border-radius: 50%;
    left: 10%;
    top: 10%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

@include laptop {
    .container {
        width: 358px;
        min-height: 284px;
        &:hover {
            .folderTop {
                top: -38px;
            }
            .bottomIcon {
                transform: rotate(90deg);
            }
        }
    }

    .folderTop {
        width: 54%;
        top: -34px;
    }

    .folderFront {
        min-height: 295px;
        height: 100%;
        padding-top: 38px;
        padding-right: 38px;
        padding-bottom: 38px;
        padding-left: 38px;
    }

    .text {
        gap: 26px;
    }

    .title {
        font-size: 34px;
    }

    .description {
        font-size: 18px;
        margin-left: 0;
        @include font-300;
    }

    .icons {
        padding-top: 38px;
        padding-right: 60px;
        padding-bottom: 20px;
        padding-left: 0;
    }

    .topIcon {
        height: 40px;
    }

    .bottomIcon {
        height: 34px;
        position: relative;
        left: 40px;
    }
}
