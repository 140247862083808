@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    z-index: 0;
    box-sizing: border-box;
    &_front {
        transform: perspective(400px) rotateY(0);
    }
    &_back {
        transform: perspective(400px) rotateY(180deg);
    }
    &_cyberpunk {
        background-color: $block5_cyberpunk;
        .circle {
            background: $button-bg-future-hover;
        }
    }
    &_future {
        background-color: $block4_future;
        .circle {
            background: $light_future;
        }
    }
}

.container_front_isExpanded {
    transform: perspective(400px) rotateY(-180deg);
}

.container_back_isExpanded {
    transform: perspective(400px) rotateY(0deg);
}

.collapseIconContainer {
    position: absolute;
    background: $bg-future;
    width: 38px;
    height: 38px;
    bottom: 20px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: rgba($color: $bg-future, $alpha: 0.6);
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}

.container_back_isExpanded .collapseIconContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .collapseIconItem {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
        transition: height 0.2s ease-in-out;
        &:nth-child(1) {
            transform: translate(0, 0);
        }
        &:nth-child(2) {
            display: block;
        }
        &:nth-child(3) {
            transform: translate(-0, 0) rotate(0);
        }
    }
}

.collapseIconItem {
    width: 2px;
    height: 10px;
    background: $text-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: height 0.2s ease-in-out;
    &:nth-child(1) {
        transform: translate(-50%, -50%);
    }
    &:nth-child(2) {
        display: none;
    }
    &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.advantage {
    width: 100%;
    min-height: 424px;
    padding-top: 30px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    overflow: hidden;
    position: relative;
    transition: background-color 0.5s ease-in-out;
    .text {
        display: flex;
        flex-direction: column;
        gap: 22px;
        padding-top: 0;
        padding-right: 22px;
        padding-bottom: 0;
        padding-left: 22px;
        position: relative;
        z-index: 1;
    }

    .title {
        font-size: 24px;
        color: $text-light;
        @include font-700;
    }

    .description {
        font-size: 16px;
        color: $text-light;
        line-height: 32px;
        @include font-400;
    }

    .imageContainerDesktop {
        display: none;
        position: relative;
        z-index: 1;
    }

    .imageContainerMobile {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        img {
            max-width: 100%;
            position: relative;
            z-index: 1;
        }
    }

    &:nth-of-type(1) {
        grid-area: first;

        .imageContainerMobile {
            justify-content: flex-end;

            img {
                width: 95%;
            }
        }
    }

    &:nth-of-type(2) {
        grid-area: second;
        .imageContainerDesktop {
            height: 90%;
            img {
                margin-top: 5%;
            }
        }
        .imageContainerMobile {
            width: 80%;
            margin: 0 auto;
        }
    }

    &:nth-of-type(3) {
        grid-area: third;

        .imageContainerMobile {
            justify-content: center;
            padding-bottom: 40px;
            box-sizing: border-box;

            img {
                width: 50%;
                max-width: 300px;
            }
        }
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: 11%;
    bottom: 12%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

.circle.active {
    transition: all 0.5s linear;
    opacity: 0.4;
    transform: scale(140);
}

@include laptop {
    .container {
        padding-top: 0;
    }

    .collapseIconContainer {
        width: 70px;
        height: 70px;
        bottom: 28px;
        right: 34px;
    }

    .collapseIconItem {
        width: 2px;
        height: 20px;
    }
    .advantage {
        height: 100%;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;

        .text {
            height: 100%;
            padding-left: 52px;
            justify-content: center;
        }

        .title {
            font-size: 32px;
        }

        .description {
            font-size: 18px;
        }

        .imageContainerMobile {
            display: none;
        }

        .imageContainerDesktop {
            display: flex;
        }

        &:nth-of-type(1) {
            min-height: 667px;

            .text {
                padding-top: 20px;
            }

            .imageContainerDesktop {
                justify-content: flex-end;

                img {
                    width: 90%;
                }
            }
        }

        &:nth-of-type(2) > div {
            .imageContainerDesktop {
                padding-right: 76px;
                justify-content: flex-end;
                img {
                    width: auto;
                    height: 100%;
                }
            }
        }

        &:nth-of-type(3) > div {
            .imageContainerDesktop {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 54px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                img {
                    height: 80%;
                    width: auto;
                }
            }
        }

        &:nth-of-type(2) > div,
        &:nth-of-type(3) > div {
            flex-direction: row;
        }
    }
}
