@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.link {
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    @include font-500;

    &_text {
        font-size: 12px;

        &_future,
        &_cyberpunk {
            color: $text-light;
        }
    }

    &_button {
        font-size: 13px;
        padding-top: 12px;
        padding-right: 15px;
        padding-bottom: 12px;
        padding-left: 15px;
        border-radius: 12px;

        &_future {
            background-color: $button-bg-future;
            color: $text-dark;
            & > .circle {
                background: $button-bg-future-hover;
            }
        }

        &_cyberpunk {
            background-color: $button-bg-cyberpunk;
            color: $text-light;
            & > .circle {
                background: $darkest-blue;
            }
        }
    }
    & > span {
        position: relative;
        z-index: 1;
    }
    &:hover {
        .circle {
            transition: all 0.5s linear;
            opacity: 0.5;
            transform: scale(150);
        }
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    left: 10%;
    top: 10%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 0;
}

@include laptop {
    .link {
        &_text {
            font-size: 14px;
        }

        &_button {
            font-size: 16px;
            padding-top: 13px;
            padding-right: 32px;
            padding-bottom: 13px;
            padding-left: 32px;
        }
    }
}
