@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    font-size: 12px;
    min-width: 35px;
    position: relative;
}

.valueContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 2px;
}

.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 10px);
    opacity: 0;
    transform: translate(0, -7px);
    transition: all 0.1s ease-out;
    pointer-events: none;
    background: rgba($color: $bg-future, $alpha: 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 8px;
    overflow: hidden;
    &_expanded {
        opacity: 1;
        transform: translate(0, 0);
        pointer-events: all;
    }
    &_cyberpunk {
        background: rgba(70, 65, 255, 0.8);
        .option {
            &_active,
            &:hover {
                background: rgba(253, 69, 193, 0.8);
            }
        }
    }
}

.option {
    display: block;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    cursor: pointer;
    transition: all 0.1s ease-out;
    margin: 2px;
    border-radius: 8px;
    &:hover,
    &_active {
        background-color: rgba($color: $text-light, $alpha: 0.5);
    }
}

@include mobile-md {
    .valueContainer {
        gap: 10px;
    }
}

@include laptop {
    .container {
        font-size: 14px;
    }
}
