@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    padding: 45px 16px 0;
}

.title {
    width: 74%;
    font-size: 24px;
    color: $text-light;
    text-align: center;
    line-height: 32px;
    position: relative;
    z-index: 1;
}

.switch {
    position: relative;
    z-index: 1;
}

.tariffs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    @include adaptiveWidth;
    position: relative;
}

.tariff {
    background: $text-light;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 36px;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    position: relative;

    &Name {
        font-size: 18px;
        @include font-500;
        position: relative;
        z-index: 1;
    }

    &Title {
        font-size: 20px;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000000;
        padding-bottom: 20px;
        margin-bottom: 18px;
        @include font-600;
        position: relative;
        z-index: 1;
    }

    &_cyberpunk {
        background-color: #eaefff;
        color: $light-blue;

        .button {
            background-color: $light-blue;
            position: relative;
            z-index: 1;
        }
    }

    &_disabled {
        background-color: #f6f5f8;
        color: $text-dark;
    }

    &_primary {
        background-color: $cyan-primary;
        transition: background-color 0.7s ease-in-out;
        &_cyberpunk {
            background: $light-blue;
            color: $text-light;

            .tariffTitle {
                border-bottom: 1px solid #ffffff;
                position: relative;
                z-index: 1;
            }

            .oldPrice {
                color: #b5b5b5;
                position: relative;
                z-index: 1;
            }

            .advantages {
                border-top: 1px solid #ffffff;
                position: relative;
                z-index: 1;
            }

            .button {
                background: #ffffff;
                color: $light-blue;
                position: relative;
                z-index: 1;
            }
        }
    }
}

.priceContainer {
    display: flex;
    gap: 20px;
    font-size: 35px;
    margin-top: 20px;
    @include font-700;
    position: relative;
    z-index: 1;
}

.oldPrice {
    text-decoration: line-through;
    color: $text-light;
    position: relative;
    z-index: 1;
}

.description {
    margin-top: 14px;
    font-size: 15px;
    @include font-400;
    position: relative;
    z-index: 1;
    &_disabled {
        color: #595959;
    }
}

.advantages {
    font-size: 16px;
    padding-top: 18px;
    padding-left: 28px;
    margin-top: 22px;
    border-top: 1px solid #e2e2e2;
    @include font-400;

    li {
        font-size: 14px;
        line-height: 22px;
        list-style-image: url(../../../assets/icons/checkmark.svg);
        padding-left: 20px;
        margin-bottom: 16px;
        position: relative;
        z-index: 1;
        @include font-400;
    }

    &_cyberpunk {
        li {
            list-style-image: url(../../../assets/icons/checkmark-cyberpunk.svg);
        }

        &_disabled {
            li {
                display: none;
            }
        }
    }

    &_disabled {
        color: #828282;

        li {
            list-style-image: url(../../../assets/icons/checkmark-disabled.svg);
        }
    }

    &_primary {
        border-top: 1px solid #000000;

        &_cyberpunk {
            li {
                list-style-image: url(../../../assets/icons/checkmark-cyberpunk-primary.svg);
            }
        }
    }
}

.buttonContainer {
    display: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    width: 140px;
    background: #000000;
    color: $text-light;
    padding-right: 0;
    padding-left: 0;
    transition: all 0.6s ease-in-out;
    border-radius: 14px;
}

@include tablet {
    .container {
        padding: 100px 80px 0;
    }
}

@include laptop {
    .container {
        gap: 32px;
    }

    .title {
        font-size: 32px;
    }

    .tariffs {
        flex-direction: row;
        align-items: center;
        gap: 50px;
    }

    .tariff {
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        padding-top: 18px;
        padding-right: 20px;
        padding-bottom: 36px;
        padding-left: 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
}
