@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.button {
    min-width: 100px;
    height: 36px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 12px;
    background: $text-light;
    font-size: 14px;
    cursor: pointer;
    color: $text-dark;
    text-align: center;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include font-600;
    & > span {
        position: relative;
        z-index: 1;
    }
    &_disabled {
        background: #e2e2e2 !important;
        color: #828282 !important;
        cursor: not-allowed;
    }
    &_cyberpunk {
        .circle {
            background: $pink-primary;
        }
    }
    &_future {
        .circle {
            background: $light_future;
        }
    }
    &:hover {
        .circle {
            transition: all 0.5s linear;
            opacity: 0.4;
            transform: scale(140);
        }
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: 11%;
    bottom: 12%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

@include laptop {
    .button {
        min-width: 150px;
        height: 46px;
        font-size: 16px;
        padding-top: 14px;
        padding-right: 46px;
        padding-bottom: 14px;
        padding-left: 46px;
        @include font-500;
    }
}
